
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Contact: React.FC = () => {
    return (
        <div className="contact">
            <h1>
                <FormattedMessage id="app.contact" />
            </h1>
            <p>
                <FormattedMessage id="app.contact.msg" />
            </p>
            <p><b><a href="mailto:&#101;&#109;&#097;&#105;&#108;&#064;&#102;&#111;&#120;&#045;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#045;&#115;&#111;&#108;&#117;&#116;&#105;&#111;&#110;&#115;&#046;&#101;&#117;">email [at] fox-software-solutions [dot] eu</a></b></p>
            <p>
                <FormattedMessage id="app.contact.wish" />
            </p>

            {/* <form>
                <label>
                    Name:
                    <input type="text" name="name" />
                </label>
                <br />
                <label>
                    Email:
                    <input type="email" name="email" />
                </label>
            </form> */}
        </div >
    );
}

export default Contact;