import React from 'react';
import { FormattedMessage } from 'react-intl';

const Services: React.FC = () => {
    return (
        <div className="services">
            <h1>
                <FormattedMessage id="app.services" />
            </h1>
            <h2>
                <i>
                    <FormattedMessage id="app.great" />
                </i>
                <FormattedMessage id="app.services.slogan" />
                <i> <FormattedMessage id="app.services.work" /></i>.
            </h2>
            <p>
                <FormattedMessage id="app.services.solution" />
            </p>
            <p>
                <FormattedMessage id="app.services.pitch1" />
                <b>Fox</b> <i>Software Solutions</i>
                <FormattedMessage id="app.services.pitch2" />
            </p>
            <h2>
                <FormattedMessage id="app.services.softwaredev" />
            </h2>
            <ul>
                <li>
                    <FormattedMessage id="app.services.webdev" />
                </li>
                <li>
                    <FormattedMessage id="app.services.cloud" />
                </li>
                <li>
                    <FormattedMessage id="app.services.desktop" />
                </li>
                <li>
                    <FormattedMessage id="app.services.mobile" />
                </li>
            </ul>
            <h2>
                <FormattedMessage id="app.services.project" />
            </h2>
            <ul>
                <li>
                    <FormattedMessage id="app.services.project.maintenance" />
                </li>
                <li>
                    <FormattedMessage id="app.services.project.analysis" />
                </li>
                <li>
                    <FormattedMessage id="app.services.project.agile" />
                </li>
                <li>
                    <FormattedMessage id="app.services.security" />
                </li>
            </ul>
        </div>
    );
};

export default Services;