import { useState } from 'react';
import './NavBar.css';
import logo from './fox-small-web2.png';
import { NavLink } from 'react-router-dom';
import { IoClose, IoMenu } from "react-icons/io5";
import { FormattedMessage } from 'react-intl';
import Languages from './Components/Languages';

const NavBar = ({ locale, changeLang }: any) => {
    const [isMobileMenuActive, setMobileMenuActive] = useState(false);
    return (
        <header>
            <nav className="navbar">
                <img src={logo} className="navbar-logo" alt="logo" />
                <div className="nav-name">
                    <span className="title">FOX</span>
                    <span className="name">Software Solutions</span>
                </div>
                <ul className={`navbar-links ${isMobileMenuActive ? "active" : ""}`}>
                    <li>
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                isActive ? "active" : ""
                            }
                        >
                            <FormattedMessage id="app.home" />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/services"
                            className={({ isActive }) =>
                                isActive ? "active" : ""
                            }
                        >
                            <FormattedMessage id="app.services" />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/contact"
                            className={({ isActive }) =>
                                isActive ? "active" : ""
                            }
                        >
                            <FormattedMessage id="app.contact" />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/about"
                            className={({ isActive }) =>
                                isActive ? "active" : ""
                            }
                        >
                            <FormattedMessage id="app.about" />
                        </NavLink>
                    </li>
                </ul>
                <Languages locale={locale} changeLang={changeLang}></Languages>
                <div onClick={() => setMobileMenuActive(false)} className={`nav-close ${isMobileMenuActive ? "active" : ""}`}>
                    <IoClose />
                </div>
                <div onClick={() => setMobileMenuActive(true)} className={`nav-toggle ${isMobileMenuActive ? "active" : ""}`}>
                    <IoMenu />
                </div>
            </nav>
        </header >
    );
};

export default NavBar;