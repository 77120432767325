const Languages = ({ locale, changeLang }: any) => {
    return (
        <div className="languages">
            <div onClick={() =>
                changeLang("en")
            } className={`lang ${"en" === locale ? 'currentLang' : ''}`} >
                EN
            </div>
            <p> | </p>
            <div onClick={() => changeLang("de")} className={`lang ${"de" === locale ? 'currentLang' : ''}`}>
                DE
            </div>
            <p> | </p>
            <div onClick={() => changeLang("fr")} className={`lang ${"fr" === locale ? 'currentLang' : ''}`} >
                FR
            </div >
            <p> | </p>
            <div onClick={() => changeLang("cs")} className={`lang ${"cs" === locale ? 'currentLang' : ''}`} >
                CS
            </div>
        </div>
    );
};

export default Languages;