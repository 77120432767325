import React from 'react';
import { createContext, useContext, useState } from 'react';
import './App.css';
import NavBar from './NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import Footer from './Footer';

import German from './i18n/de.json';
import French from './i18n/fr.json';
import Czech from './i18n/cs.json';
import English from './i18n/en.json';
import { IntlProvider } from 'react-intl';

function App() {
  const [locale, setLocale] = useState(navigator.language);

  const getMessages = (forLocale: string) => {
    switch (forLocale) {
      case "de":
        return German;
      case "fr":
        return French;
      case "cs":
        return Czech;
      default:
        return English;
    }
  }

  const [lang, setLang] = useState(getMessages(locale));

  const changeLang = (locale: string) => {
    setLocale(locale);
    setLang(getMessages(locale));
  }

  return (
    <IntlProvider locale={locale} messages={lang}>
      <BrowserRouter>
        <NavBar locale={locale} changeLang={changeLang} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer locale={locale} changeLang={changeLang} />
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
