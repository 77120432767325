import flag from './eu-flag.png';
import Languages from './Components/Languages';
import './Footer.css';

const Footer = ({ locale, changeLang }: any) => {
    return (
        <footer className="footer">
            <Languages locale={locale} changeLang={changeLang}></Languages>
            <span className="contact-email"><b><a href="mailto:&#101;&#109;&#097;&#105;&#108;&#064;&#102;&#111;&#120;&#045;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#045;&#115;&#111;&#108;&#117;&#116;&#105;&#111;&#110;&#115;&#046;&#101;&#117;">email [at] fox-software-solutions [dot] eu</a></b></span>
            <div className="logos">
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/tafox" className="fa fa-linkedin"></a>
                <a target="_blank" rel="noreferrer" href="https://github.com/tafox" className="fa fa-github"></a>
            </div>
            <span className="copyright">© 2025 Fox Software Solutions</span>
            <img src={flag} className="footer-flag" alt="flag" />
        </footer>
    );
};

export default Footer;