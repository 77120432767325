import React from 'react';
import { FormattedMessage } from 'react-intl';

const Home = () => {
    return (
        <div className="home">
            <span className="text">
                <FormattedMessage
                    id="home.welcome"
                />
            </span>
            <span className="title">
                FOX
            </span>
            <span className="name">
                Software Solutions
            </span>
            <p className="slogan">
                <b><i>
                    <FormattedMessage
                        id="app.serious"
                    /> </i></b>
                <FormattedMessage
                    id="app.softwaresolutions"
                />
            </p>
        </div>
    );
};

export default Home;